.KwamisInfoPage {
    overflow: hidden;
}
.KwamisInfoPage .kwami {
    float: left;
    padding: 0 5px;
    width: 100%;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
    .KwamisInfoPage .kwami {
        width: 50%;
    }
    .KwamisInfoPage .kwami:nth-child(odd) {
        clear: left;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .KwamisInfoPage .kwami {
        width: 33.33%;
    }
    .KwamisInfoPage .kwami:nth-child(3n+1) {
        clear: left;
    }
}
@media screen and (min-width: 992px) {
    .KwamisInfoPage .kwami {
        width: 25%;
    }
    .KwamisInfoPage .kwami:nth-child(4n+1) {
        clear: left;
    }
}
