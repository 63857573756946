.MiraculousImage {
    display: inline-block;
    max-width: 100%;
    padding: 5px;
    position: relative;
}
.answers .MiraculousImage {
    max-width: 50%;
}
.MiraculousImage span {
    background-color: #fff;
    bottom: calc(.25rem + 5px);
    color: #000;
    display: inline-block;
    padding: 2px 8px;
    position: absolute;
    right: calc(.25rem + 5px);
}

.clickable-img {
    cursor: pointer;
}
